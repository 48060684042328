import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { string } from "yup";
import { IRecurso, ICliente } from "../../modelos";
import { Mensaje, Input, Button, Columna } from "../../components";

type IProps = {
    urlBase: string;
    padre: IRecurso | undefined,
    recursos: IRecurso[];
    gestionadoPor: ICliente; //Persona que gestina este gestor
    gestionadoPara?: ICliente; //cliente que verá este gestor
    loading?: boolean
}

export const NuevaCarpeta = ({ padre, recursos, urlBase, ...props }: IProps) => {

    //const [urlBase, setUrlBase] = useState("");
    const [nombreCarpeta, setNombreCarpeta] = useState<string>("");
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const formSchema = string().required("El nombre de la carpeta es obligatorio").test("existe", "La carpeta ya existe", (nombre => {
        return !recursos.find(c => !!c.isFolder && c.padreID === padre?.id && c.nombre === nombre);
    }));

    // useEffect(() => {
    //     const normalizedUrl = props.urlBase.endsWith("/") ? props.urlBase.slice(0, -1) : props.urlBase;
    //     setUrlBase(normalizedUrl);
    // }, [props.urlBase])

    useEffect(() => {
        (async () => {
            try {
                await formSchema.validate(nombreCarpeta);
                setIsValid(true);
            } catch (error) {
                setIsValid(false);
            }
        })();
    }, [padre, nombreCarpeta, formSchema])

    const handleChange = (el: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setError(undefined);
        const value = el.target.value;
        setNombreCarpeta(value);
    }

    const crearCarpeta = async () => {
        if (!!props.loading) return;
        if (urlBase !== "" && !urlBase) return;
        recursos.forEach(c => {
            if (!c.isFolder && c.nombre === nombreCarpeta && c.padreID === padre?.id) {
                setIsValid(false);
                setError("La carpeta ya existe");
                return;
            }
        })
        console.log(urlBase)
        if (!!isValid) {
            try {
                console.log("A ver ", padre, urlBase)
                const carpetaRef = firebase.firestore().collection(`${urlBase}`).doc();
                const c: IRecurso = {
                    id: carpetaRef.id,
                    padreID: padre?.id || null,
                    clienteID: props.gestionadoPara?.id || null,
                    tipo: "carpeta",
                    isFolder: true,
                    nombre: nombreCarpeta,
                    url: `${padre?.url || urlBase}/${nombreCarpeta}`,
                    visible: true,
                    visto: false,
                    ///compartida: 
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    createdBy: props.gestionadoPor?.id || props.gestionadoPara?.id || null,
                    updatedBy: props.gestionadoPor?.id || props.gestionadoPara?.id || null,
                    createdByName: props.gestionadoPor?.nombre || props.gestionadoPara?.nombre || "",
                    updatedByName: props.gestionadoPor?.nombre || props.gestionadoPara?.nombre || "",
                }
                await carpetaRef.set(c);
                setNombreCarpeta("");
            } catch (error) {
                setError(error.message);
            }
        }
    }

    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        console.log(nombreCarpeta);
        await crearCarpeta();
    }


    return (
        <form onSubmit={handleSubmit}>
            <Columna gap="1rem" sizes="1fr auto">
                <Input disabled={props.loading} style={{ minWidth: 0 }} name="nombre" value={nombreCarpeta} onChange={handleChange} placeholder="Nueva carpeta" maxLength={100} />
                <Button disabled={!isValid || props.loading}>+</Button>
                {!!error && <Mensaje>{error}</Mensaje>}
            </Columna>
        </form>
    );
}