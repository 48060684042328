import React from "react";
import { Fila, Titulo } from "../../components";
import { GestorArchivos } from "./gestor-archivos";
import { ICliente } from "../../modelos";

export const CarpetaCompartida = ({ gestionadoPor, urlBase }: { gestionadoPor: ICliente, urlBase: string }) => {

    return <Fila>
        <Titulo>Carpeta compartida</Titulo>
        <GestorArchivos urlBase={urlBase} gestionadoPor={gestionadoPor} puedeSubir={true} />
    </Fila>
}