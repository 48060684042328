import React, { useContext, useMemo, useState, useEffect } from "react";
import firebase from "firebase";
import { ListadoClientes, CarpetaCompartida } from "./components";
import { Columna, Fila, Titulo, Grid, Input, Parrafo, Button, Mensaje } from "../components";
import { AdministradorContext } from "../contextos";
import { ICliente, nuevoCliente, clienteFromSnapshot } from "../modelos";
import { object, string, ValidationError } from "yup";
import { useParams, useHistory, Link } from "react-router-dom";
import { config } from "../utils";

type IProps = {
}

export const Editar = (props: IProps) => {
    const { clienteId } = useParams();
    const history = useHistory();
    const { administrador } = useContext(AdministradorContext);

    const [cliente, setCliente] = useState<ICliente | undefined>(nuevoCliente());
    const [clienteAntes, setClienteAntes] = useState<ICliente | undefined>(nuevoCliente());
    const [email, setEmail] = useState("");
    const [error, setError] = useState<string | undefined>(undefined);
    const [erroresForm, setErroresForm] = useState<ValidationError | undefined>(undefined);
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!!clienteId) {
            const unsubscribe = firebase.firestore().doc(`clientes/${clienteId}`).onSnapshot(c => {
                setError(undefined);
                if (!!c.exists) {
                    const cli = clienteFromSnapshot(c);
                    setCliente(cli);
                    setClienteAntes({ ...cli });
                    setEmail(cli?.email || "");
                } else {
                    setCliente(undefined);
                }
            }, error => {
                setError(error.message);
            });
            return () => unsubscribe();
        }
    }, [clienteId]);

    const formSchema = useMemo(() => object({
        nombre: string().required(`El nombre es obligatorio`).min(2, "El nombre debe contener al menos 2 dígitos").max(150, `El nombre debe contener 150 dígitos como máximo`),
        //email: string().required("El Email es obligatorio").email("El email no es válido"),
        codigo: string().required("El código es obligatorio").max(100, `Debe contener 100 dígitos como máximo`),
        cif: string(),
    }), []);


    const handleChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {

        const cli = { ...cliente, [ev.target.name]: ev.target.type === "checkbox" ? ev.target.checked : ev.target.value };
        setCliente(cli);
        try {
            await formSchema.validate(cli);
            setErroresForm(undefined);
            setIsValid(true);
        } catch (error) {
            console.error(error);
            setIsValid(false);
            setErroresForm(error);
        }
    }

    const handleSubmit = (ev) => {
        ev.preventDefault();
        setError(undefined);
        (async () => {
            if (isValid) {
                try {
                    const c: ICliente = {
                        codigo: cliente?.codigo,
                        nombre: cliente?.nombre,
                        cif: cliente?.cif,
                        activo: cliente?.activo,
                        carpetaCompartida: cliente?.carpetaCompartida,
                        updatedAt: new Date(),
                        updatedBy: administrador?.id
                    };
                    console.log("Actualizamos el cliente ", c);
                    await cliente?.firestoreRef?.update(c);
                    history.push("/admin/clientes");
                } catch (error) {
                    setError(error.message);
                }
            }
        })();
    }

    const toggleActive = async () => {
        try {
            const activo = !!!clienteAntes?.activo;
            if (!!activo) {
                setLoading(true);
                console.log("Enviando link al cliente");
                const resultado = await fetch(config.EnviarLinkClienteUrl, {
                    body: JSON.stringify({
                        codigo: clienteAntes?.codigo,
                        email: clienteAntes?.email,
                        nombre: clienteAntes?.nombre,
                        cif: clienteAntes?.cif,
                        activo: true,
                        carpetaCompartida: clienteAntes?.carpetaCompartida,
                    }),
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log(resultado);
            }
            await clienteAntes?.firestoreRef?.update({ activo: activo, updatedAt: new Date(), updatedBy: administrador?.id, activatedAt: !!activo ? new Date() : null });
            setLoading(false)
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    return <Fila gap="1rem">
        <Link style={{ justifySelf: "start", border: "1px solid #0c5f82", marginBottom: "1rem", textDecoration: "none", padding: "0.5rem 1rem", borderRadius: "5px" }} to={`/admin/clientes/${clienteId}`} >Volver</Link>
        <Titulo>Editar cliente</Titulo>
        <Fila>
            <Columna gap="1rem" sizes="1fr 1fr">
                <Fila gap=".5rem">
                    <Parrafo>Código de cliente: {clienteAntes?.codigo}</Parrafo>
                    <Parrafo>Email: {clienteAntes?.email}</Parrafo>
                    <Parrafo>CIF: {clienteAntes?.cif}</Parrafo>
                    <Parrafo style={{ color: !!clienteAntes?.activo ? "black" : "red" }}>El cliente está {clienteAntes?.activo ? "activo" : "inactivo"}</Parrafo>
                    <Parrafo>Acceso a carpeta compartida: {!!clienteAntes?.carpetaCompartida ? "Si" : "No"}</Parrafo>
                </Fila>
                <Fila gap=".5rem">
                    <Parrafo>Fecha de creación: {clienteAntes?.createdAt?.toLocaleDateString()}</Parrafo>
                    <Parrafo>Fecha de activación: {clienteAntes?.activatedAt?.toLocaleDateString()}</Parrafo>
                    <Parrafo>Fecha último inicio de sesión: {clienteAntes?.lastSigning?.toLocaleDateString() || "Nunca ha iniciado sesión"}</Parrafo>
                </Fila>
            </Columna>
            <Button disabled={!!loading} onClick={toggleActive} style={{ justifySelf: "start", margin: "1rem 0" }}>{!!loading ? "Activando..." : !!clienteAntes?.activo ? "Desactivar" : "Activar"}</Button>
        </Fila>

        <hr style={{ margin: "2rem 0" }} />
        <form onSubmit={handleSubmit}>
            <Grid gap="1rem" style={{ gridTemplateColumns: "repeat(2, minmax(20rem, 1fr))" }}>
                <Input type="text" name="codigo" value={cliente?.codigo} onChange={handleChange} placeholder="Código de cliente" maxLength={10} style={{ borderColor: erroresForm?.path === "codigo" && erroresForm?.errors[0] ? "red" : "" }} />
                {/* {erroresForm?.path === "codigo" && <p>{erroresForm.errors[0]}</p>} */}
                {/* <Input disabled={true} type="email" name="email" value={cliente?.email} onChange={handleChange} placeholder="Email" maxLength={150} style={{ borderColor: erroresForm?.path === "email" && erroresForm?.errors[0] ? "red" : "" }} /> */}
                <Input type="text" name="nombre" value={cliente?.nombre} onChange={handleChange} placeholder="Nombre" maxLength={150} style={{ borderColor: erroresForm?.path === "nombre" && erroresForm?.errors[0] ? "red" : "" }} />
                <Input type="text" name="cif" value={cliente?.cif} onChange={handleChange} placeholder="CIF" maxLength={10} style={{ borderColor: erroresForm?.path === "cif" && erroresForm?.errors[0] ? "red" : "" }} />
                <Columna>
                    <label htmlFor="carpetaCompartida">
                        <Columna sizes="auto 1fr" gap="1rem">
                            <Input name="carpetaCompartida" type="checkbox" onChange={handleChange} checked={cliente?.carpetaCompartida} />
                            <Parrafo>Acceso a carpeta compartida</Parrafo>
                        </Columna>
                    </label>
                    {/* <label htmlFor="carpetaCompartida">
                        <Columna sizes="auto 1fr" gap="1rem">
                            <Input name="isAdmin" type="checkbox" onChange={handleChange} checked={cliente.isAdmin} />
                            <Parrafo>Es administrador</Parrafo>
                        </Columna>
                    </label> */}
                </Columna>
                <Button type="submit" disabled={!isValid} >Actualizar cliente</Button>
                {!!error && <Mensaje>{error}</Mensaje>}
                {!!erroresForm && <Mensaje>{erroresForm.message}</Mensaje>}
            </Grid>

        </form>
    </Fila>
}