import React, { useState, useEffect, useMemo } from "react";
import firebase from "firebase";
import { Titulo, Input, Mensaje, Columna, Fila, Tabla, Parrafo } from "../../components";
import { ICliente, clienteFromSnapshot } from "../../modelos";
import { SortOrder, Column } from 'react-base-table';
import 'react-base-table/styles.css';
import { useHistory } from "react-router-dom";

export const ListadoClientes = (props) => {
    const history = useHistory()
    const [clientes, setClientes] = useState<ICliente[]>([]);
    const [clientesFiltrados, setClientesFiltrados] = useState<ICliente[]>([])
    const [filtro, setFiltro] = useState("");
    const [inicioSesion, setInicioSesion] = useState(false);
    const [activo, setActivo] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [sortBy, setSortBy] = useState({ key: 'nombre', order: SortOrder.DESC })

    useEffect(() => {
        const unsubscribe = firebase.firestore()
            .collection("clientes")
            .where("isAdmin", "==", false)
            .orderBy("codigo")
            .onSnapshot(snapshot => {
                const resultado: ICliente[] = [];
                if (!snapshot.empty) {
                    snapshot.docs.forEach(c => {
                        const cliente = clienteFromSnapshot(c);
                        if (cliente) {
                            resultado.push(cliente);
                        }
                    })
                }
                setClientes(resultado);
            }, error => {
                setError(error.message);
                console.log(error)
            });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const palabra = filtro.toLowerCase();
        if (palabra.length > 0) {
            const cF = clientes.filter(c => {
                const nombre = c.nombre?.toLowerCase() || "";
                const email = c.email?.toLowerCase() || "";
                const cif = c.cif?.toLowerCase() || "";
                const codigo = c.codigo?.toLowerCase() || "";
                const grupo = c.grupo?.toLowerCase() || "";
                return (nombre.includes(palabra) || email.includes(palabra) || cif.includes(palabra) || codigo.includes(palabra) || grupo.includes(palabra))
            });
            setClientesFiltrados(cF);
        } else {
            setClientesFiltrados(clientes);
        }
    }, [filtro, clientes]);

    useEffect(() => {
        if (!!inicioSesion || !!activo) {
            let filtrado = [...clientes];
            if (!!inicioSesion) {
                filtrado = filtrado.filter(c => !!c.lastSigning)
            }
            if (!!activo) {
                filtrado = filtrado.filter(c => !c.activo);
            }
            setClientesFiltrados(filtrado)
        } else {
            setFiltro("")
            setClientesFiltrados(clientes);
        }
    }, [inicioSesion, activo, clientes])

    const columnSort = (sort) => {
        setSortBy(sort);
        const c = clientesFiltrados.sort((a, b) => ((a[sort.key]) > (b[sort.key])) ? sort.order === "asc" ? 1 : -1 : -1);
        setClientesFiltrados(c);
    }

    const eventHandler = useMemo(() => {
        return {
            onClick: ({ rowData, rowIndex, rowKey, event }) => {
                history.push(`/admin/clientes/${rowData.id}`);
            }
        }
    }, [history]);

    return (
        <Fila gap="1rem">
            <Columna align="center" sizes="1fr auto">
                <Titulo>Listado de clientes</Titulo>
                <Input name="buscador" onChange={ev => setFiltro(ev.target.value)} value={filtro} placeholder="Buscador" />
            </Columna>
            <Columna gap="1rem" align="center" justify="start" sizes="auto">
                <label htmlFor="sesion">
                    <Columna align="center" gap="1rem">
                        <Parrafo>Solo los que no han iniciado sesión</Parrafo>
                        <Input type="checkbox" name="sesion" onChange={ev => setInicioSesion(ev.target.checked)} checked={inicioSesion} />
                    </Columna>
                </label>
                <label htmlFor="activos">
                    <Columna align="center" gap="1rem">
                        <Parrafo>Sólo inactivos</Parrafo>
                        <Input type="checkbox" name="activos" onChange={ev => setActivo(ev.target.checked)} checked={activo} />
                    </Columna>
                </label>
            </Columna>
            {!error && <Tabla
                data={clientesFiltrados}
                width={600}
                height={600}
                sortBy={sortBy}
                onColumnSort={columnSort}
                rowEventHandlers={eventHandler}
            >
                <Column title="Codigo" key="codigo" dataKey="codigo" width={100} sortable={true} />
                <Column title="Nombre" key="nombre" dataKey="nombre" width={200} sortable={true} />
                <Column title="Activo" key="activo" dataKey="activo" width={100} sortable={true} cellRenderer={data => (!!data.cellData ? <p>Activo</p> : <p style={{ color: "red", fontWeight: "bold" }}>Inactivo</p>)} />
                <Column title="Inicio sesión" key="lastSigning" dataKey="lastSigning" width={200} sortable={true} cellRenderer={data => (!!data.cellData ? <p>{data.cellData.toLocaleDateString() || "Si"}</p> : <p style={{ color: "red", fontWeight: "bold" }}>No</p>)} />
            </Tabla>}
            {
                error && <Mensaje>{error}</Mensaje>
            }

        </Fila>
    );
}