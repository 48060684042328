import React, { useContext } from "react";
import { Switch, Redirect } from "react-router-dom";
import { Clientes, Archivos } from ".";
import { AdminRoute } from "../private-route";
import { Header } from "./components";
import { AdministradorContext, useAdministradores, AuthContext } from "../contextos";
import { Editar } from "./Editar";


export const Admin = () => {
    const { cliente } = useContext(AuthContext);

    const { administrador, administradores } = useAdministradores(cliente);

    return (
        <AdministradorContext.Provider value={{ administrador, administradores }} >
            <Header />
            <main className="main">
                <Switch>
                    <AdminRoute exact path="/admin/clientes" component={Clientes} />
                    <AdminRoute path="/admin/editar/:clienteId" component={Editar} />
                    <AdminRoute path="/admin/clientes/:clienteId" component={Archivos} />
                    <Redirect to="/admin/clientes" />
                </Switch>
            </main>
        </AdministradorContext.Provider>
    );
}