export interface ICliente {
    firestoreRef?: firebase.firestore.DocumentReference;
    id?: string;
    codigo?: string;
    nombre?: string;
    email?: string;
    cif?: string;
    grupo?: string;
    activo?: boolean;
    carpetaCompartida?: boolean;
    notas?: string;
    isAdmin?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    activatedAt?: Date;
    lastSigning?: Date;
    createdBy?: string;
    updatedBy?: string;
}

export const nuevoCliente = () => {
    return {
        codigo: "",
        nombre: "",
        email: "",
        cif: "",
        activo: false,
        notas: "",
        carpetaCompartida: true,
        isAdmin: false
    }
}

export const clienteFromSnapshot = (cliente: firebase.firestore.DocumentSnapshot): ICliente | undefined => {
    if (!cliente.exists || !cliente.data()) return undefined;
    const c = cliente.data()!;
    return {
        firestoreRef: cliente.ref,
        id: cliente.ref.id,
        codigo: c['codigo'],
        nombre: c['nombre'],
        email: c['email'],
        cif: c['cif'],
        grupo: c['grupo'],
        activo: !!c['activo'],
        carpetaCompartida: !!c['carpetaCompartida'],
        notas: c['notas'],
        isAdmin: !!c['isAdmin'],
        createdAt: c["createdAt"]?.toDate() || undefined,
        updatedAt: c["updatedAt"]?.toDate() || undefined,
        activatedAt: c["activatedAt"]?.toDate() || undefined,
        lastSigning: c["lastSigning"]?.toDate() || undefined,
        createdBy: c["createdBy"],
        updatedBy: c["updatedBy"],
    }
}