import { createContext, useEffect, useState } from 'react';
import firebase from "firebase";
import { ICliente, clienteFromSnapshot } from '../modelos/Cliente';


type IAuthContext = {
    user?: firebase.User;
    cliente?: ICliente;
    error?: string;
    loading: boolean;
}

export const AuthContext = createContext<IAuthContext>({ loading: false });

export const useAuth = () => {
    const [user, setUser] = useState<firebase.User | undefined>(firebase.auth().currentUser || undefined);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(
            user => {
                console.log("Subscription useAuth: ", user);
                setUser(user || undefined);
            },
            error => {
                setUser(undefined);
                console.log(error);
            },
        );

        return () => unsubscribe();
    }, []);
    return user;
}

export const useCliente = ({ user }: { user?: firebase.User }) => {
    const [cliente, setCliente] = useState<ICliente | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        if (!!user && user.uid) {
            const unsubscribe = firebase.firestore().doc(`clientes/${user.uid}`).onSnapshot(
                clienteRef => {
                    console.log("Subscription useClientes: ", clienteRef);
                    if (clienteRef.exists) {
                        setCliente(clienteFromSnapshot(clienteRef));
                    } else {
                        setCliente(undefined);
                    }
                    setError(undefined);
                    setLoading(false);
                },
                error => {
                    setCliente(undefined);
                    setError(error.message);
                    setLoading(false);
                    console.error(error);
                });

            return () => unsubscribe();
        } else {
            setCliente(undefined);
            setError(undefined);
            setLoading(false);
        }
    }, [user]);
    return { cliente, error, loading }
}

type IAdministradoresContext = {
    administrador?: ICliente;
    administradores: ICliente[];
}
export const AdministradorContext = createContext<IAdministradoresContext>({ administradores: [] })

type IAdministradoresProps = {
    cliente?: ICliente
}

export const useAdministradores = (cliente?: ICliente) => {
    const [administradores, setAdministradores] = useState<ICliente[]>([]);
    const [administrador, setAdministrador] = useState<ICliente | undefined>(undefined);

    useEffect(() => {
        if (!!cliente && !!cliente.id) {
            const unsubscribe = firebase.firestore().collection(`clientes`).where("isAdmin", "==", true)
                .onSnapshot(admins => {
                    const result: ICliente[] = [];
                    admins.docs.forEach(a => result.push(clienteFromSnapshot(a)!));
                    setAdministradores(result);
                }, err => {
                    console.error(err);
                    setAdministradores([]);
                });
            return () => unsubscribe();
        } else {
            setAdministradores([]);
        }
    }, [cliente]);

    useEffect(() => {
        if (!!cliente && cliente.isAdmin) {
            const existe = administradores.find(a => a.id === cliente.id);
            setAdministrador({ ...existe });
        } else {
            setAdministrador(undefined);
        }
    }, [cliente, administradores]);

    return { administrador, administradores }
}