import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase";
import { useParams, Link, useHistory } from "react-router-dom";
import { clienteFromSnapshot, nuevoCliente, ICliente, recursoFromSnapshot } from "../modelos";
import { DatosCliente, GestorArchivos } from "./components";
import { Mensaje, Fila, Titulo, Parrafo } from "../components";
import { AdministradorContext } from "../contextos";
import { Utils } from "../utils";

export const Archivos = () => {
    const { clienteId } = useParams();
    const { administrador, administradores } = useContext(AdministradorContext);

    const [cliente, setCliente] = useState<ICliente | undefined>(nuevoCliente());
    const [error, setError] = useState<string | undefined>(undefined);
    const [createdBy, setCreatedBy] = useState<ICliente | undefined>(undefined);
    const [updatedBy, setUpdatedBy] = useState<ICliente | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (!!clienteId) {
            const unsubscribe = firebase.firestore().doc(`clientes/${clienteId}`).onSnapshot(c => {
                setError(undefined);
                if (!!c.exists) {
                    setCliente(clienteFromSnapshot(c));
                } else {
                    setCliente(undefined);
                }
            }, error => {
                setError(error.message);
            });
            return () => unsubscribe();
        }
    }, [clienteId]);

    useEffect(() => {
        setCreatedBy(administradores.find(a => a.id === cliente?.createdBy));
        setUpdatedBy(administradores.find(a => a.id === cliente?.updatedBy));
    }, [administradores, administrador, cliente])

    const eliminar = async (c?: ICliente) => {
        if (!!c?.id) {
            setLoading(true);
            await (async () => {
                try {
                    const referencias = await c.firestoreRef?.collection('documentos').get();
                    const recursos = referencias?.docs.map(r => recursoFromSnapshot(r)!) || [];
                    await Utils.forEachAsync(recursos, async (r, i) => {
                        try {
                            if (!r.isFolder) {
                                await firebase.storage().ref(r.url).delete();
                            }
                            await r.firestoreRef?.delete();
                        } catch (error) {
                            console.error("Error al eliminar el fichero", error, r);
                        }
                    });
                    await c.firestoreRef?.delete();
                    setLoading(false);
                    history.push(`/admin/clientes`)
                } catch (error) {
                    console.error("Error al eliminar el cliente");
                    setLoading(false);
                }
            })();
        }
    }

    const editar = (cliente?: ICliente) => {
        if (!!cliente?.id) {
            history.push(`/admin/editar/${cliente.id}`);
        }
    }

    return <Fila>
        <Link style={{ justifySelf: "start", border: "1px solid #0c5f82", marginBottom: "1rem", textDecoration: "none", padding: "0.5rem 1rem", borderRadius: "5px" }} to="/admin/clientes" >Volver</Link>
        {loading && <Parrafo>El cliente se está eliminando. No cierre la ventana.</Parrafo>}
        {!!cliente && <DatosCliente onDelete={eliminar} onEdit={editar} cliente={cliente} createdBy={createdBy} updatedBy={updatedBy} />}
        <hr style={{ margin: "1rem 0" }} />
        <Fila>
            <Titulo>Documentos para el cliente</Titulo>
            {!!cliente?.id && <GestorArchivos urlBase={`clientes/${cliente.id}/documentos`} gestionadoPor={administrador} gestionadoPara={cliente} puedeSubir={true} />}
            <hr style={{ margin: "1rem 0" }} />
        </Fila>
        <Fila>
            <Titulo>Documentos del cliente</Titulo>
            {!!cliente?.id && <GestorArchivos urlBase={`clientes/${cliente.id}/cliente`} gestionadoPor={administrador} gestionadoPara={cliente} puedeSubir={true} />}
        </Fila>
        {!!error && <Mensaje>{error}</Mensaje>}
    </Fila>
}