import React from "react";
import { ICliente } from "../../modelos";
import { Columna, Titulo, Parrafo, Fila, Button } from "../../components";

type IProps = {
    cliente?: ICliente;
    createdBy?: ICliente;
    updatedBy?: ICliente;
    onDelete: (cliente?: ICliente) => void;
    onEdit: (cliente?: ICliente) => void
}

export const DatosCliente = ({ cliente, createdBy, updatedBy, ...resto }: IProps) => {

    return (
        <Fila gap="1rem" margin="0 0 2rem 0">
            <Columna sizes="1fr auto auto" gap="1rem" align="center" justify="start">
                <Titulo>{!!cliente?.grupo ? `(${cliente?.grupo}) - ` : ""}{cliente?.nombre}</Titulo>
                <Button onClick={() => resto.onEdit(cliente)}>Editar</Button>
                <Button onClick={() => resto.onDelete(cliente)}>Eliminar</Button>
            </Columna>
            <Columna gap="1rem" sizes="1fr 1fr">
                <Fila gap=".5rem">
                    <Parrafo>Código de cliente: {cliente?.codigo}</Parrafo>
                    <Parrafo>Email: {cliente?.email}</Parrafo>
                    <Parrafo>CIF: {cliente?.cif}</Parrafo>
                    <Parrafo style={{ color: !!cliente?.activo ? "black" : "red" }}>El cliente está {cliente?.activo ? "activo" : "inactivo"}</Parrafo>
                    <Parrafo>Acceso a carpeta compartida: {!!cliente?.carpetaCompartida ? "Si" : "No"}</Parrafo>
                </Fila>
                <Fila gap=".5rem">
                    <Parrafo>Fecha de creación: {cliente?.createdAt?.toLocaleDateString()}</Parrafo>
                    <Parrafo>Fecha de activación: {cliente?.activatedAt?.toLocaleDateString()}</Parrafo>
                    <Parrafo>Fecha último inicio de sesión: {cliente?.lastSigning?.toLocaleDateString() || "Nunca ha iniciado sesión"}</Parrafo>
                    {!!createdBy && <Parrafo>Creado por: {createdBy.nombre}</Parrafo>}
                    {!!updatedBy && <Parrafo>Actualizado por: {updatedBy.nombre}</Parrafo>}
                </Fila>
            </Columna>
        </Fila>
    );

}