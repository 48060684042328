import styled from "styled-components";


export const Input = styled.input`
    border: 1px solid black;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 2px;

    &:disabled{
        opacity: 0.4;
    }
`;

export const Button = styled.button`
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background: #0c5f82;
    color: white;
    border: none;
    border-radius: 2px;
    &:disabled{
        opacity: 0.4;
    }
    &:focus, &:visited, &:active{
        outline: none;
    }
`;


export const Enlace = styled.a`
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background: #0c5f82;
    color: white;
    border: none;
    border-radius: 2px;
    text-align: center;
    text-decoration: none;
    height: 100%;
    &:disabled{
        opacity: 0.4;
    }
`;

export const Select = styled.select`
    height: 2rem;
    font-size: 1rem;
`;

export const Option = styled.option`
    padding: 0.5rem 1rem;
`;