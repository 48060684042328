import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase";
import { Parrafo, Columna, Fila, Button, Enlace } from "../../components";
import { IArchivo, ICarpeta, ICliente, IRecurso } from "../../modelos";
import { AdministradorContext, AuthContext } from "../../contextos";

type IInformacion = {
    recursos: IRecurso[];
    puedeEliminar: boolean;
    disabled: boolean;
    eliminar: (recursos: IRecurso[]) => void;
    descargar: (recurso: IRecurso) => void;
}

export const Informacion = ({ recursos, puedeEliminar, disabled, eliminar, descargar }: IInformacion) => {
    const { cliente } = useContext(AuthContext);
    const [url, setUrl] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (recursos.length === 1) {
            if (!recursos[0].isFolder) {
                firebase.storage().ref(recursos[0].url).getDownloadURL().then(u => {
                    console.log(u)
                    setUrl(u);
                }).catch(err => {
                    console.error(err);
                    setUrl(undefined);
                });
            }
        }
    }, [recursos]);

    return <Fila gap="1rem">
        <div className="Informacion">
            {
                recursos.length === 1
                    ? <>
                        <Parrafo>Nombre: {recursos[0].nombre}</Parrafo>
                        {recursos[0].size && <Parrafo>Tamaño: {(recursos[0].size / 1024 / 1024).toFixed(2)}MB</Parrafo>}
                        {/* <Parrafo>Url: {recursos[0].url}</Parrafo> */}
                        <Parrafo>Tipo: {!!recursos[0].isFolder ? "Carpeta" : (recursos[0].tipo || "Archivo")}</Parrafo>
                        {!!cliente?.isAdmin && <>
                            <Parrafo>Visible: {recursos[0].visible === true ? "Si" : "No"}</Parrafo>
                            <Parrafo>Visto: {recursos[0].visto === true ? "Si" : "No"}</Parrafo>
                            <Parrafo>Fecha creación: {recursos[0].createdAt?.toLocaleDateString()}</Parrafo>
                            <Parrafo>Fecha modificación: {recursos[0].updatedAt?.toLocaleDateString()}</Parrafo>
                            <Parrafo>Creado por: {recursos[0].createdByName || "cliente"}</Parrafo>
                            <Parrafo>Actualizado por: {recursos[0].updatedByName || "cliente"}</Parrafo>
                        </>}
                    </>
                    : <>
                        <Parrafo>{recursos.length} archivos</Parrafo>
                        <Parrafo>{`Tamaño: ${(recursos.reduce((p, n) => p + (n.size || 0), 0) / 1024 / 1024).toFixed(2)}MB`}</Parrafo>
                    </>
            }

        </div>
        <hr />
        <Columna gap="1rem">
            {!!url && <Enlace href={url} rel="noopener noreferrer" target="_blank" >Descargar</Enlace>}
            {/* {!!url && !recurso.isFolder && <Link to={url} target="_blank" download>Descargar</Link>} */}
            {/* {!recurso.isFolder && <Button onClick={async () => await onDescargar(recurso)} disabled={disabled}>Ver/Descargar</Button>} */}
            {!!puedeEliminar && <Button disabled={!puedeEliminar || !!disabled} onClick={() => eliminar(recursos)}>Eliminar</Button>}
        </Columna>
    </Fila>
}



type IPropsArchivo = {
    archivo: IArchivo;
    eliminar: (archivo: IArchivo) => void;
    eliminando: boolean;
    puedeEliminar: boolean;
}

export const InformacionArchivo = ({ archivo, eliminando, eliminar, puedeEliminar }: IPropsArchivo) => {
    const { administrador, administradores } = useContext(AdministradorContext);
    const [createdBy, setCreatedBy] = useState<ICliente | undefined>(undefined);
    const [updatedBy, setUpdatedBy] = useState<ICliente | undefined>(undefined);

    useEffect(() => {
        if (archivo.createdBy) {
            setCreatedBy(administradores.find(a => a.id === archivo.createdBy));
        }
        if (archivo.updatedBy) {
            setUpdatedBy(administradores.find(a => a.id === archivo.updatedBy))
        }
    }, [administrador, administradores, archivo])

    return <Fila gap="1rem">
        <div className="InformacionArchivo">
            <Parrafo>Nombre: {archivo.nombre}</Parrafo>
            {archivo.size && <Parrafo>Tamaño: {archivo.size / 1024 / 1024}</Parrafo>}
            <Parrafo>Url: {archivo.url}</Parrafo>
            <Parrafo>Tipo: {archivo.tipo}</Parrafo>
            <Parrafo>Visible: {archivo.visible === true ? "Si" : "No"}</Parrafo>
            <Parrafo>Visto: {archivo.visto === true ? "Si" : "No"}</Parrafo>
            <Parrafo>Fecha creación: {archivo.createdAt?.toLocaleDateString()}</Parrafo>
            <Parrafo>Fecha modificación: {archivo.updatedAt?.toLocaleDateString()}</Parrafo>
            <Parrafo>Creado por: {createdBy?.nombre || "cliente"}</Parrafo>
            <Parrafo>Actualizado por: {updatedBy?.nombre || "cliente"}</Parrafo>
        </div>
        <hr />
        <Columna gap="1rem">
            <Button>Ver/Descargar {JSON.stringify(puedeEliminar)}</Button>
            <Button disabled={!puedeEliminar || !!eliminando} onClick={() => eliminar(archivo)}>Eliminar</Button>
        </Columna>
    </Fila>
}

type IPropsCarpeta = {
    carpeta: ICarpeta;
    eliminar: (carpeta: ICarpeta) => void;
    eliminando: boolean;
    puedeEliminar: boolean;
}

export const InformacionCarpeta = ({ carpeta, eliminar, eliminando, puedeEliminar, ...props }: IPropsCarpeta) => {
    const { administrador, administradores } = useContext(AdministradorContext);
    const [createdBy, setCreatedBy] = useState<ICliente | undefined>(undefined);
    const [updatedBy, setUpdatedBy] = useState<ICliente | undefined>(undefined);

    useEffect(() => {
        if (carpeta.createdBy) {
            setCreatedBy(administradores.find(a => a.id === carpeta.createdBy));
        }
        if (carpeta.updatedBy) {
            setUpdatedBy(administradores.find(a => a.id === carpeta.updatedBy))
        }
    }, [administrador, administradores, carpeta])


    return <Fila gap="1rem">
        <div className="InformacionArchivo">
            <Parrafo>Nombre: {carpeta.nombre}</Parrafo>
            {carpeta.padre && <Parrafo>Carpeta: {carpeta.padre}</Parrafo>}
            <Parrafo>Compartida: {carpeta.compartida === true ? "Si" : "No"}</Parrafo>
            <Parrafo>Visible: {carpeta.visible === true ? "Si" : "No"}</Parrafo>
            <Parrafo>Fecha creación: {carpeta.createdAt?.toLocaleDateString()}</Parrafo>
            <Parrafo>Fecha modificación: {carpeta.updatedAt?.toLocaleDateString()}</Parrafo>
            <Parrafo>Creado por: {createdBy?.nombre || "cliente"}</Parrafo>
            <Parrafo>Actualizado por: {updatedBy?.nombre || "cliente"}</Parrafo>
        </div>
        <hr />
        <Columna gap="1rem">
            <Button disabled={!puedeEliminar || !!eliminando} onClick={() => eliminar(carpeta)}>Eliminar</Button>
        </Columna>
    </Fila>
}