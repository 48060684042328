import React, { useContext } from "react";
import styled from "styled-components";
import { NuevoCliente, ListadoClientes, CarpetaCompartida } from "./components";
import { Columna } from "../components";
import { AdministradorContext } from "../contextos";

export const ClientesComp = (props) => {

    const { administrador } = useContext(AdministradorContext);

    return <div className={props.className}>
        <NuevoCliente />
        <hr style={{ margin: "2rem 0" }} />
        <Columna gap="2rem">
            <ListadoClientes />
            {!!administrador && <CarpetaCompartida gestionadoPor={administrador} urlBase={"documentos-compartidos"} />}
        </Columna>

    </div>
}

export const Clientes = styled(ClientesComp)``;