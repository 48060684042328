import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './contextos';

export const ClientRoute = ({ component: Component, ...rest }) => {

    const { user, cliente } = useContext(AuthContext);

    return (
        <Route {...rest}
            render={props => {
                if (!user || !cliente || !cliente.activo) return <Redirect to="/" />
                if (!!cliente && !!cliente.id && !cliente.isAdmin) return <Component {...props} />
                return <Redirect to="/" />
            }}
        />
    );
}


export const AdminRoute = ({ component: Component, ...rest }) => {

    const { user, cliente } = useContext(AuthContext);

    return (
        <Route {...rest}
            render={props => {
                if (!user || !cliente) return <Redirect to="/" />
                if (!!cliente && !!cliente.id && !!cliente.isAdmin) return <Component {...props} />
                return <Redirect to="/" />
            }}
        />
    );
}
