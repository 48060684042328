import styled from "styled-components"

interface IGridProps {
    gap?: string;
    padding?: string;
    margin?: string;
    sizes?: string;
    width?: string;
    height?: string;
    justify?: string;
    align?: string;
}

export const Grid = styled.div<IGridProps>`
    display: grid;
    width: ${props => props.width || '100%'};
    max-width: 100%;
    height: ${props => props.height || 'auto'};
    max-height: 100%;
    overflow: auto;
    gap: ${props => props.gap || '0rem'};
    padding: ${props => props.padding || '0rem'};
    margin: ${props => props.margin || '0rem'};
    align-items: ${props => props.align || 'start'};
    justify-content: ${props => props.justify || 'stretch'};
`;

export const Columna = styled(Grid)`
    grid-auto-flow: column;
    grid-template-columns: ${props => props.sizes || 'auto'};
`;

export const Fila = styled(Grid)`
    grid-auto-flow: row;
    grid-template-rows: ${props => props.sizes || 'auto'};
`;