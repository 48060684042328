export interface IRecurso {
    firestoreRef?: firebase.firestore.DocumentReference;
    id?: string;
    padreID?: string | null;
    clienteID?: string | null;
    tipo?: string;
    isFolder?: boolean;

    nombre?: string;
    url?: string;

    size?: number;

    compartida?: boolean;
    visible?: boolean;
    visto?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: string | null;
    updatedBy?: string | null;
    createdByName?: string;
    updatedByName?: string;
}

export const recursoFromSnapshot = (recurso: firebase.firestore.DocumentSnapshot): IRecurso | undefined => {
    if (!recurso.exists || !recurso.data()) return undefined;
    const c = recurso.data()!;
    return {
        firestoreRef: recurso.ref,
        id: recurso.ref.id,
        padreID: c['padreID'],
        clienteID: c['clienteID'],
        tipo: c['tipo'],
        isFolder: !!c['isFolder'],
        nombre: c['nombre'],
        url: c['url'],
        size: c['size'],
        compartida: !!c['compartida'],
        visible: !!c['visible'],
        visto: !!c['visto'],
        createdAt: c["createdAt"]?.toDate() || undefined,
        updatedAt: c["activatedAt"]?.toDate() || undefined,
        createdBy: c["createdBy"],
        updatedBy: c["updatedBy"],
        createdByName: c["createdByName"],
        updatedByName: c["updatedByName"],
    }
}

export interface ICarpeta {
    firestoreRef?: firebase.firestore.DocumentReference;
    id?: string;
    clienteID?: string;
    nombre?: string;
    padre?: string;
    url?: string;
    compartida?: boolean;
    visible?: boolean;
    orden?: number;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: string;
    updatedBy?: string;
}

export interface IArbolCarpeta extends ICarpeta {
    children?: IArbolCarpeta[];
}

export interface IArchivo {
    firestoreRef?: firebase.firestore.DocumentReference;
    storageRef?: firebase.storage.Reference;
    id?: string;
    carpetaID?: string | null;
    clienteID?: string;
    nombre?: string;
    size?: number;
    url?: string;
    tipo?: string;
    visible?: boolean;
    visto?: boolean;
    orden?: number;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: string;
    updatedBy?: string;
}

export const carpetaFromSnapshot = (carpeta: firebase.firestore.DocumentSnapshot): ICarpeta | undefined => {
    if (!carpeta.exists || !carpeta.data()) return undefined;
    const c = carpeta.data()!;
    return {
        firestoreRef: carpeta.ref,
        id: carpeta.ref.id,
        clienteID: c['clienteID'],
        nombre: c['nombre'],
        padre: c['padre'],
        url: c['url'],
        compartida: !!c['compartida'],
        visible: !!c['visible'],
        orden: c['orden'],
        createdAt: c["createdAt"]?.toDate() || undefined,
        updatedAt: c["activatedAt"]?.toDate() || undefined,
        createdBy: c["createdBy"],
        updatedBy: c["updatedBy"],
    }
}

export const archivoFromSnapshot = (archivo: firebase.firestore.DocumentSnapshot): IArchivo | undefined => {
    if (!archivo.exists || !archivo.data()) return undefined;
    const c = archivo.data()!;
    return {
        firestoreRef: archivo.ref,
        id: archivo.ref.id,
        carpetaID: c['carpetaID'] || undefined,
        clienteID: c['clienteID'],
        nombre: c['nombre'],
        size: c['size'],
        url: c['url'],
        tipo: c['tipo'],
        visible: !!c['visible'],
        visto: !!c['visto'],
        orden: c['orden'],
        createdAt: c["createdAt"]?.toDate() || undefined,
        updatedAt: c["activatedAt"]?.toDate() || undefined,
        createdBy: c["createdBy"],
        updatedBy: c["updatedBy"],
    }
}

export const generarArbolCarpetas = (elementos: ICarpeta[]): IArbolCarpeta[] => {
    let hashTable: IArbolCarpeta = {};
    elementos.forEach(aData => hashTable[aData.id!] = { ...aData, children: [] })
    let dataTree: IArbolCarpeta[] = [];
    elementos.forEach(aData => {
        if (aData.padre !== "") { hashTable[aData.padre!].children.push(hashTable[aData.id!]) }
        else {
            dataTree.push(hashTable[aData.id!])
        }
    })
    return dataTree
}

export const isArchivo = (archivo: any): archivo is IArchivo => (archivo as IArchivo).carpetaID !== undefined || (archivo as IArchivo).size !== undefined;
export const isCarpeta = (carpeta: any): carpeta is ICarpeta => (carpeta as ICarpeta).padre !== undefined;

