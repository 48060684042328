import styled from "styled-components";
import { Columna } from "./grid";


export const Gestor = styled.div`
    border: 1px solid black;
    gap: 1rem;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(5rem, 5rem));
    align-items: start;
    min-height: 300px;
    min-width: 60%;
    width: 100%;

    &.loading{
        border-color: #ccc;
        img, p{
            opacity: 0.4;
        }
    }
`;

export const RutaNav = styled(Columna)`
    gap: 0.5rem;
    justify-content: start;
`;

export const Ruta = styled.button`
    padding: 0 .2rem;
    border: none;
    background: transparent;
    span{
        padding: 0 .2rem;
    }
    &:focus, &:visited, &:active{
        outline: none;
    }
`;

export const Carpeta = styled.div`
    display: grid;
    grid-auto-flow: row;
    border: 1px solid transparent;
    grid-template-rows: repeat(auto-fit,minmax(3rem, 3rem));
    cursor: default;
    height: 100%;
    max-height: 6rem;
    text-align:center;
    img{
        max-height: 100%;
        padding: 0.2rem .5rem;
        margin: 0 auto;
    }
    p{
        cursor: default;
        font-size: 0.7rem;
        text-align: center;
        height: 100%;
        text-overflow: ellipsis;
        word-break: break-word;
        display: block;
        overflow: hidden;
    }
    &.selected{
        border: 1px solid #ccc;
        border-radius: 5px;
    }
`;


export const Archivo = styled(Carpeta)`
    
`;