import React, { useEffect, useState } from "react";
import ReactDropzone from 'react-dropzone';
import styled from "styled-components";
import { Utils } from "../../utils";
import { IRecurso, ICliente } from "../../modelos";
import firebase from "firebase";

type IProps = {
    disabled?: boolean;
    recursos: IRecurso[];
    baseUrl: string;
    carpetaActual?: IRecurso;
    gestionadoPor: ICliente; //Persona que gestina este gestor
    gestionadoPara?: ICliente; //cliente que verá este gestor
    onLoading: (loading: boolean) => void;
}

export const SubidaArchivos = ({ onLoading, ...props }: IProps) => {
    const [loading, setLoading] = useState(false);
    const [recursos, setRecursos] = useState<IRecurso[]>([]);
    // const { getRootProps, getInputProps } = useDropzone({
    //     onDrop: docs => {
    //         if (!!loading || docs.length <= 0) return;
    //         setLoading(true);
    //         onSubirArchivos(docs).then(() => setLoading(false)).catch(err => setLoading(false));
    //     }
    // });

    useEffect(() => {
        setRecursos([...props.recursos]);
    }, [props.recursos]);

    useEffect(() => {
        onLoading(loading);
    }, [loading, onLoading])

    const onSubirArchivos = async (docs: File[]) => {
        console.log("INICIO SUBIDA DE ARCHIVOS Y CARPETAS", docs);
        setLoading(true);
        let exito = true;
        let carpetas: string[] = [];
        docs.forEach(f => {
            const rutaArr: string = (f as any).path;
            const rutaArchivo = rutaArr.split("/").filter(r => r !== "");
            const ruta = rutaArchivo.slice(0, -1);

            if (!ruta || ruta.length === 0) {
            } else {
                if (ruta[0]) carpetas.push(`${props.carpetaActual?.url || props.baseUrl}/${ruta[0]}`)
                ruta.reduce((prev, curr) => {
                    console.log(prev, curr)
                    if (!carpetas.includes(`${props.carpetaActual?.url || props.baseUrl}/${prev}/${curr}`)) carpetas.push(`${props.carpetaActual?.url || props.baseUrl}/${prev}/${curr}`);
                    return `${prev}/${curr}`;
                });
            }
        });
        carpetas = Array.from(new Set(carpetas)).filter(c => !recursos.some(r => r.url === c)); //Carpetas a crear
        const carpetasCreadas: IRecurso[] = [];
        console.log(carpetas)

        //Crear todas las carpetas nuevas
        await Utils.forEachAsync(carpetas, async (carpetaACrear, i) => {
            const directorioArr = carpetaACrear.split("/").filter(r => r !== "")
            const directorioAnterior = directorioArr.slice(0, -1).join("/");
            const nombreCarpeta = directorioArr[directorioArr.length - 1];
            let padre = recursos.find(r => {
                return !!r.isFolder && r.url === directorioAnterior
            });
            if (!padre) padre = carpetasCreadas.find(c => c.url === directorioAnterior);

            console.log("directorio anterior: ", directorioAnterior, " | Directorio a crear: ", carpetaACrear, " | Nombre carpeta: ", nombreCarpeta, " | Padre: ", padre, " | carpetas creadas: ", carpetasCreadas);
            const carpetaRef = firebase.firestore().collection(`${props.baseUrl}`).doc();
            const nuevaCarpeta: IRecurso = {
                id: carpetaRef.id,
                padreID: padre?.id || null,
                clienteID: props.gestionadoPara?.id || null,
                tipo: "carpeta",
                isFolder: true,
                nombre: nombreCarpeta,
                url: `${padre?.url || props.carpetaActual?.url || props.baseUrl}/${nombreCarpeta}`,
                visible: true,
                visto: false,
                ///compartida: 
                createdAt: new Date(),
                updatedAt: new Date(),
                createdBy: props.gestionadoPor?.id || props.gestionadoPara?.id || null,
                updatedBy: props.gestionadoPor?.id || props.gestionadoPara?.id || null,
                createdByName: props.gestionadoPor?.nombre || props.gestionadoPara?.nombre || "",
                updatedByName: props.gestionadoPor?.nombre || props.gestionadoPara?.nombre || "",
            }
            try {
                await carpetaRef.set(nuevaCarpeta);
                carpetasCreadas.push({ ...nuevaCarpeta, firestoreRef: carpetaRef });
            } catch (error) {
                console.error("Error al crear carpeta: ", error);
                exito = false;
            }
        });
        if (!exito) return;

        //Subir archivos a carpetas
        const archivo: IRecurso = {
            clienteID: props.gestionadoPara?.id || null,
            isFolder: false,
            compartida: false,
            visible: true,
            visto: false,
            createdAt: new Date(),
            updatedAt: new Date(),
            createdBy: props.gestionadoPor?.id || null,
            updatedBy: props.gestionadoPara?.id || null,
            createdByName: props.gestionadoPor?.nombre || "",
            updatedByName: props.gestionadoPor?.nombre || "",
        };

        await Utils.forEachAsync(docs, async (file, i) => {
            const rutaArr: string = (file as any).path;
            const rutaArchivo = rutaArr.split("/").filter(r => r !== "");
            const ruta = `${props.carpetaActual?.url || props.baseUrl}/${rutaArchivo.slice(0, -1).join("/")}`;
            const nombre = rutaArchivo[rutaArchivo.length - 1];

            const storage = firebase.storage().ref();
            let resultadoStorage: firebase.storage.UploadTaskSnapshot | undefined;

            let carpetaPadre = recursos.find(r => !!r.isFolder && r?.url === ruta);
            if (!carpetaPadre) carpetaPadre = carpetasCreadas.find(c => c?.url === ruta);
            console.log("carpetaPadre: ", carpetaPadre, " | ruta: ", ruta)
            let subido = true;

            try {
                const fileRef = storage.child(`${carpetaPadre?.url || props.carpetaActual?.url || props.baseUrl}/${nombre}`);
                resultadoStorage = await fileRef.put(file);
            } catch (error) {
                console.error("Error subiendo archivo", error, file);
                subido = false;
            }
            if (!!subido && !!resultadoStorage) {
                try {
                    const archivoExistente = recursos.find(r => !r.isFolder && r.url === resultadoStorage?.metadata?.fullPath);
                    if (!!archivoExistente) {
                        const archivoFinal = {
                            ...archivoExistente,
                            clienteID: props.gestionadoPara?.id || null,
                            padreID: carpetaPadre?.id || props.carpetaActual?.id || null,
                            tipo: resultadoStorage.metadata.contentType,
                            nombre: resultadoStorage.metadata.name,
                            url: resultadoStorage.metadata.fullPath,
                            size: resultadoStorage.metadata.size,
                            visto: false,
                            updatedAt: new Date(),
                            updatedBy: props.gestionadoPor?.id || props.gestionadoPara?.id || null,
                            updatedByName: props.gestionadoPor?.nombre || props.gestionadoPara?.nombre || "",
                        }
                        delete archivoFinal.firestoreRef;
                        await archivoExistente.firestoreRef?.update(archivoFinal);
                    } else {
                        const archivoRef = firebase.firestore().collection(`${props.baseUrl}`).doc();
                        const a = {
                            ...archivo,
                            id: archivoRef.id,
                            padreID: carpetaPadre?.id || props.carpetaActual?.id || null,
                            tipo: resultadoStorage.metadata.contentType,
                            nombre: resultadoStorage.metadata.name,
                            url: resultadoStorage.metadata.fullPath,
                            size: resultadoStorage.metadata.size,
                        }
                        await archivoRef.set(a);
                        console.log(a)
                    }

                } catch (error) {
                    console.error("Error guardando archivo en BBDD", error, file);
                    subido = false;
                    try {
                        await storage.child(resultadoStorage.metadata.fullPath).delete()
                    } catch (error) {
                        console.error("No se ha creado el archivo en BBDD y fallo al eliminar de Storage", error);
                    }
                }
            }

        });
        setLoading(false);
        console.log("FIN SUBIDA DE ARCHIVOS Y CARPETAS");
    }

    return <SubidaArchivosStyle>
        <ReactDropzone onDrop={onSubirArchivos} disabled={!!loading} >
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: `dropzone ${!!loading ? "disabled" : ""}` })}>
                    <input {...getInputProps()} />
                    <p>{!!loading ? "Subiendo archivos..." : "Arrastrar archivos o carpetas para subir al servidor."}</p>
                </div>
            )}
        </ReactDropzone>
        {/* <div {...getRootProps({ className: `dropzone ${!!loading ? "disabled" : ""}` })}>
            <input {...getInputProps()} />
            <p>{!!loading ? "Subiendo archivos..." : "Arrastrar archivos o carpetas para subir al servidor."}</p>
        </div> */}
    </SubidaArchivosStyle>
}

export const SubidaArchivosStyle = styled.div`
    border: 1px dotted #ccc;
    margin: 2rem 0;
    p{
        padding: 2rem 1rem;
    }
`;