import React, { useContext, useEffect } from "react";
import { Header, GestorArchivos } from "./components";
import { AuthContext } from "../contextos";
import { Fila, Titulo } from "../components";

export const Home = () => {
    const { cliente } = useContext(AuthContext);

    useEffect(() => {
        if (!!cliente) {
            //const data: ICliente = { ...cliente, lastSigning: new Date() };
            (async () => {
                try {
                    console.log("actualizando datos", cliente)
                    await cliente.firestoreRef?.update({ lastSigning: new Date() });
                } catch (error) {
                    console.error(error);
                }
            })();
        }
    }, []);


    return <div className="Home">
        <Header />
        <main className="main">
            <Fila gap="1rem">
                <Titulo>Documentos</Titulo>
                {!!cliente?.id && <GestorArchivos urlBase={`clientes/${cliente.id}/documentos`} puedeSubir={false} gestionadoPara={cliente} gestionadoPor={cliente} urlCompartidos="documentos-compartidos" />}
            </Fila>
            <hr style={{ margin: "1rem 0" }} />
            <Fila gap="1rem">
                <Titulo>Mis documentos</Titulo>
                {!!cliente?.id && <GestorArchivos urlBase={`clientes/${cliente.id}/cliente`} puedeSubir={true} gestionadoPara={cliente} gestionadoPor={cliente} />}
            </Fila>
        </main>
    </div>
}