import React, { useEffect, useState, useMemo, useRef } from 'react';
import { ICliente, IRecurso, recursoFromSnapshot } from '../../modelos';
import { Fila, Columna, Gestor, Carpeta, Archivo, Ruta, RutaNav, Mensaje, Input } from '../../components';
import firebase from 'firebase';
import CarpetaImg from "../../assets/images/carpeta.png";
import ArchivoImg from "../../assets/images/archivo.png";
import { Utils } from '../../utils';
import { NuevaCarpeta, Informacion } from '.';
import { SubidaArchivos } from './subida-archivos';

type IProps = {
    puedeSubir?: boolean;
    urlBase: string;
    gestionadoPor?: ICliente; //Persona que gestina este gestor
    gestionadoPara?: ICliente; //cliente que verá este gestor
    urlCompartidos?: string;
}

export const GestorArchivos = ({ puedeSubir, urlBase, ...props }: IProps) => {

    const [recursos, setRecursos] = useState<IRecurso[]>([]); //*
    const [recursosFiltrados, setRecursosFiltrados] = useState<IRecurso[]>([]); //*

    const [recursosCompartidos, setRecursosCompartidos] = useState<IRecurso[]>([]); //*
    const [recursosCompartidosFiltrados, setRecursosCompartidosFiltrados] = useState<IRecurso[]>([]); //*

    const [filtro, setFiltro] = useState(""); //*
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined); //*
    const [, setFiles] = useState<FileList | undefined>(undefined); //*
    const filesRef = useRef<HTMLInputElement>(null);  //*

    const [carpetaActual, setCarpetaActual] = useState<IRecurso | undefined>(undefined); //*
    //const [recursoSeleccionado, setRecursoSeleccionado] = useState<IRecurso | undefined>(undefined) //*
    const [recursosSeleccionados, setRecursosSeleccionados] = useState<IRecurso[]>([]);

    useEffect(() => {
        let resultado: IRecurso[] = []
        setError(undefined);
        if (!!urlBase) {
            console.log("Subscription to recursos");
            const unsubscribe = firebase.firestore()
                .collection(`${urlBase}`)
                .orderBy("nombre", "asc")
                .onSnapshot(recursosSnap => {
                    resultado = recursosSnap.docs.map(r => recursoFromSnapshot(r)!);
                    onDeseleccionar();
                    setRecursos(resultado);
                }, error => setError(error.message));
            return () => unsubscribe();
        } else {
            setRecursos(resultado);
        }
    }, [urlBase]);

    useEffect(() => {
        if (props.urlCompartidos && props.urlCompartidos.length > 0 && !!props.gestionadoPara?.carpetaCompartida) {
            let resultado: IRecurso[] = []
            setError(undefined);
            if (!!props.urlCompartidos) {
                console.log("Subscription to recursos compartidos");
                const unsubscribe = firebase.firestore()
                    .collection(`${props.urlCompartidos}`)
                    .orderBy("nombre", "asc")
                    .onSnapshot(recursosSnap => {
                        resultado = recursosSnap.docs.map(r => recursoFromSnapshot(r)!);
                        onDeseleccionar();
                        setRecursosCompartidos(resultado);
                    }, error => setError(error.message));
                return () => unsubscribe();
            } else {
                setRecursosCompartidos(resultado);
            }
        } else {
            setRecursosCompartidos([]);
        }
    }, [props.urlCompartidos, props.gestionadoPara]);

    useEffect(() => {
        if (filtro.length > 0) {
            onDeseleccionar();
            const f = filtro.toLowerCase();
            setRecursosFiltrados(recursos.filter(r => r.nombre?.toLowerCase().includes(f)));
            setRecursosCompartidosFiltrados(recursosCompartidos.filter(r => r.nombre?.toLowerCase().includes(f)));
        } else {
            setRecursosFiltrados(recursos);
            setRecursosCompartidosFiltrados(recursosCompartidos);
        }
    }, [filtro, recursos, recursosCompartidos]);

    const irACarpeta = (carpeta?: IRecurso) => {
        setCarpetaActual(carpeta);
        //setRecursoSeleccionado(undefined);
    }

    const onDeseleccionar = (ev?) => {
        if (!!ev) ev.stopPropagation();
        //setRecursoSeleccionado(undefined);
        setRecursosSeleccionados([]);
    }

    const onSeleccionar = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, elemento?: IRecurso) => {
        ev.stopPropagation();
        //setRecursoSeleccionado(elemento);
        if (!!elemento?.id) {
            if (!recursosSeleccionados.some(r => r.id === elemento.id)) {
                setRecursosSeleccionados(rrss => [...rrss, elemento]);
            } else {
                setRecursosSeleccionados(rrss => rrss.filter(r => r.id !== elemento.id));
            }
        } else {
            setRecursosSeleccionados(rrss => rrss.filter(r => r.id !== elemento?.id));
        }


    }

    const onEliminar = async (elementos: IRecurso[]) => {
        setError(undefined);
        setLoading(true);
        await Utils.forEachAsync(elementos, async (elemento, i) => {
            if (!!elemento.isFolder) {
                const subcarpetas = getSubcarpetas(elemento, recursos.filter(c => !!c.isFolder)).reverse();
                console.log("Comienzo eliminación de carpetas: ", subcarpetas);
                await Utils.forEachAsync(subcarpetas, async (c, i) => {
                    console.log("Eliminando carpeta ", c);
                    try {
                        await (eliminarCarpetaYArchivos(c, urlBase));
                    } catch (error) {
                        console.error("Error eliminando carpeta", error, c);
                    }
                })
            } else {
                if (elemento.url) {
                    try {
                        await firebase.storage().ref(elemento.url).delete();
                        await elemento.firestoreRef?.delete();
                    } catch (error) {
                        setError(error.message);
                    }
                }
            }
            setRecursosSeleccionados(rrss => rrss.filter(r => r.id !== elemento.id));
        });
        setLoading(false);
    }

    const ruta = useMemo(() => {
        if (!carpetaActual) return <RutaNav><Ruta>Raíz<span>/</span></Ruta></RutaNav>;
        let r: IRecurso[] = [];
        let c: IRecurso | undefined = carpetaActual;
        let i = 0;
        do {
            i++;
            if (!!c && !!c.isFolder) r.push(c!);
            c = findCarpetaPadre(recursos, c); // carpetas.find(carpeta => carpeta.id === c?.padre);
        } while ((!!c) && i < 10);
        r = r.reverse();
        return (<RutaNav>
            <Ruta onClick={() => irACarpeta()}>Raíz<span>/</span></Ruta>
            {r.map(carpeta => <Ruta key={carpeta.id} onClick={() => irACarpeta(carpeta)}>{carpeta.nombre}<span>/</span></Ruta>)}
        </RutaNav>)
    }, [carpetaActual, recursos])

    const onFileChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        console.log("onfilechange");
        setFiles(ev.target.files || undefined);
        await subirArchivos(ev.target.files || undefined)
    }

    const subirArchivos = async (files: FileList | undefined) => {
        console.log("Metodo subir archivos")
        if (!files || files.length <= 0) return;
        const ficheros: File[] = [];
        for (let i = 0; i < files.length; i++) {
            ficheros.push(files[i]);
        }

        console.log("Comenzamos a subir archivos ", ficheros.length);
        setError(undefined);
        setLoading(true);
        const storage = firebase.storage().ref();
        await Utils.forEachAsync(ficheros, async (file, index) => {
            await (async () => {
                try {
                    const url = !!carpetaActual?.url ? `${carpetaActual?.url}/${file.name}` : `${urlBase}/${file.name}`
                    const fileRef = storage.child(url);
                    const result = await fileRef.put(file);
                    console.log("Resultado de subida: ", result);
                    const archivoRef = firebase.firestore().collection(`${urlBase}`).doc();
                    const archivo: IRecurso = {
                        id: archivoRef.id,
                        padreID: carpetaActual?.id || null,
                        clienteID: props.gestionadoPara?.id || null,
                        tipo: result.metadata.contentType || undefined,
                        isFolder: false,
                        nombre: result.metadata.name,
                        url: result.metadata.fullPath,
                        size: result.metadata.size,
                        visible: true,
                        visto: false,
                        ///compartida: 
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        createdBy: props.gestionadoPor?.id || props.gestionadoPara?.id || null,
                        updatedBy: props.gestionadoPor?.id || props.gestionadoPara?.id || null,
                        createdByName: props.gestionadoPor?.nombre || props.gestionadoPara?.nombre || "",
                        updatedByName: props.gestionadoPor?.nombre || props.gestionadoPara?.nombre || "",
                    }
                    console.log("Guardando datos archivo: ", archivo);
                    await archivoRef.set(archivo);

                } catch (error) {
                    console.error("Error al subir: ", error);
                    setError(error.message);
                    setLoading(false);
                }
            })();
        });
        setFiles(undefined);
        if (filesRef && filesRef.current) {
            filesRef.current.files = null;
        };
        setLoading(false);
        console.log("Terminamos de subir archivos ", ficheros.length);
    }

    return (
        <Fila gap="1rem" padding="1rem 0">
            <Columna gap="1rem" justify="start" align="center" sizes="auto 1fr " >
                <Input name="buscador" onChange={ev => setFiltro(ev.target.value)} value={filtro} placeholder="Buscar" />
                {ruta}
                {/* {!!puedeSubir && <label htmlFor="files">
                    <span style={{ marginRight: "1rem" }}>Subir archivos</span>
                    <input disabled={loading} name="files" type="file" multiple onChange={onFileChange} ref={filesRef} />
                </label>} */}
            </Columna>
            <Columna sizes="3fr 1fr" gap="1rem">
                <Gestor className={loading ? "loading" : ""} onClick={onDeseleccionar} >
                    {
                        recursosCompartidosFiltrados.filter(c => !!c.isFolder && c.padreID === (carpetaActual?.id || null)).map(c => (
                            <Carpeta className={!!recursosSeleccionados.find(r => r.id === c.id) ? "selected" : ""} onClick={(ev) => onSeleccionar(ev, c)} onDoubleClick={() => irACarpeta(c)} key={c.id}>
                                <img src={CarpetaImg} alt="carpeta" />
                                <p>{c.nombre}</p>
                            </Carpeta>
                        ))
                    }
                    {
                        recursosFiltrados.filter(c => !!c.isFolder && c.padreID === (carpetaActual?.id || null)).map(c => (
                            <Carpeta className={!!recursosSeleccionados.find(r => r.id === c.id) ? "selected" : ""} onClick={(ev) => onSeleccionar(ev, c)} onDoubleClick={() => irACarpeta(c)} key={c.id}>
                                <img src={CarpetaImg} alt="carpeta" />
                                <p>{c.nombre}</p>
                            </Carpeta>
                        ))
                    }
                    {
                        recursosCompartidosFiltrados.filter(a => !a.isFolder && a.padreID === (carpetaActual?.id || null)).map(a => (
                            <Archivo className={!!recursosSeleccionados.find(r => r.id === a.id) ? "selected" : ""} onClick={(ev) => onSeleccionar(ev, a)} key={a.id}>
                                <img src={ArchivoImg} alt="archivo" />
                                <p>{a.nombre}</p>
                            </Archivo>
                        ))
                    }
                    {
                        recursosFiltrados.filter(a => !a.isFolder && a.padreID === (carpetaActual?.id || null)).map(a => (
                            <Archivo className={!!recursosSeleccionados.find(r => r.id === a.id) ? "selected" : ""} onClick={(ev) => onSeleccionar(ev, a)} key={a.id}>
                                <img src={ArchivoImg} alt="archivo" />
                                <p>{a.nombre}</p>
                            </Archivo>
                        ))
                    }
                </Gestor>
                <Fila>
                    {!!puedeSubir && !!props.gestionadoPor && <NuevaCarpeta loading={loading} gestionadoPor={props.gestionadoPor} gestionadoPara={props.gestionadoPara} urlBase={urlBase} padre={carpetaActual} recursos={recursos} />}
                    {recursosSeleccionados.length > 0 && (<Informacion
                        recursos={recursosSeleccionados}
                        puedeEliminar={!!puedeSubir}
                        disabled={loading}
                        descargar={(recurso) => console.log(recurso)} eliminar={recursos => onEliminar(recursos)} />)}
                    {!!puedeSubir && !!props.gestionadoPor && <SubidaArchivos onLoading={l => setLoading(l)} carpetaActual={carpetaActual} baseUrl={urlBase} recursos={recursos} disabled={!puedeSubir} gestionadoPor={props.gestionadoPor} gestionadoPara={props.gestionadoPara} />}
                    {/* {!!recursoSeleccionado && <InformacionArchivo archivo={archivoSeleccionado} eliminar={async archivo => await onEliminarArchivo(archivo)} eliminando={eliminando} puedeEliminar={!!puedeSubir} />}
                    {!!carpetaSeleccionada && <InformacionCarpeta carpeta={carpetaSeleccionada} eliminar={async carpeta => await onEliminarCarpeta(carpeta)} eliminando={eliminando} puedeEliminar={!!puedeSubir} />} */}
                </Fila>
            </Columna>
            {!!error && <Mensaje>{error}</Mensaje>}
        </Fila>
    );
}

const findCarpetaPadre = (arr: IRecurso[], carpeta?: IRecurso): IRecurso | undefined => {
    if (!carpeta) return undefined;
    return arr.find(c => (!!c.isFolder && c.id === carpeta.padreID));
}

const getSubcarpetas = (carpeta: IRecurso, carpetas: IRecurso[]): IRecurso[] => {
    let resultado: IRecurso[] = [carpeta];
    carpetas.filter(c => !!c.isFolder && c.padreID === carpeta.id)
        .forEach(c => resultado = [...resultado, ...getSubcarpetas(c, carpetas)]);
    return resultado;
}

const eliminarCarpetaYArchivos = async (fromCarpeta: IRecurso, urlBase: string) => {
    try {
        const carpetaAEliminar = { ...fromCarpeta };
        console.log(carpetaAEliminar)
        const archivosRef = await firebase.firestore()
            .collection(`${urlBase}`)
            .where("isFolder", "==", false)
            .where("padreID", "==", carpetaAEliminar.id)
            .get();
        const archivos: IRecurso[] = [];
        if (!archivosRef.empty) {
            archivosRef.docs.forEach(a => { if (a.exists) archivos.push(recursoFromSnapshot(a)!) });
        }
        //Eliminamos los archivos y la carpeta de la base de datos
        const batch = firebase.firestore().batch();
        console.log("antes de foreach", archivos);
        await Utils.forEachAsync(archivos, async (a, i) => {
            if (!!a.firestoreRef) {
                try {
                    await firebase.storage().ref(a.url).delete();
                    console.log("batch archivo a eliminar", a)
                    batch.delete(a.firestoreRef!);
                } catch (error) {
                    console.error("Error eliminando archivo: ", a);
                }
            }
        });
        console.log("batch carpeta a eliminar", carpetaAEliminar)
        if (carpetaAEliminar.firestoreRef) batch.delete(carpetaAEliminar.firestoreRef);
        await batch.commit();
    } catch (error) {
        console.error("Error al eliminar carpeta y archivos: ", error);
    }
}