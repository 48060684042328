import React, { useMemo } from 'react';
import { config } from './utils';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";
import "firebase/storage";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { AdminRoute, ClientRoute } from './private-route';
import { useCliente, useAuth, AuthContext } from "./contextos";
import { Login, Home, Admin, Password } from './paginas';
import { Parrafo, Grid } from './components';
import { Reset } from 'styled-reset'

firebase.initializeApp(config.firebase);
console.clear();

function App() {
  const user = useAuth();
  const { cliente, loading, error } = useCliente({ user });

  const routes = useMemo(() => {
    if (!!loading) {
      return <Grid align="center" justify="center">
        <Parrafo>Cargando...</Parrafo>
      </Grid>
    }
    if (!!error) {
      return <Grid align="center" justify="center">
        <Parrafo>{error}</Parrafo>
      </Grid>
    }
    return <Router>
      <Route exact path="/" component={Login} />
      {(!cliente || !user || !cliente.id) && <Redirect to="/" />}
      <ClientRoute exact path="/home" component={Home} />
      <Route exact path="/remember" component={Password} />
      <AdminRoute path="/admin" component={Admin} />
      {!cliente?.isAdmin && <Redirect to="/home" />}
      {!!cliente?.isAdmin && <Redirect to="/admin" />}
    </Router>

  }, [cliente, error, loading, user]);

  return (
    <>
      <Reset />
      <AuthContext.Provider value={{ user, cliente, loading, error }}>
        {routes}
      </AuthContext.Provider>
    </>
  );
}

export default App;
