import React, { useState } from "react";
import { Fila, Input, Button, Titulo, Mensaje, Parrafo } from "../../components";
import firebase from "firebase";

export const RememberForm = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        remember()
    }

    const remember = async () => {
        setError(undefined);
        setLoading(true);
        try {
            await firebase.auth().sendPasswordResetEmail(email).catch(e => { throw e });
        }
        catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
        //firebase.auth().signInWithEmailAndPassword(email, password).then(m => console.log(m)).catch(e => console.log(e))
    }

    return <Fila gap="1rem">
        <Titulo>Recordar contraseña</Titulo>
        {!!loading && <Parrafo>Enviando enlace...</Parrafo>}
        {
            !loading && <>
                <Input type="email" name="email" value={email} onChange={ev => setEmail(ev.target.value)} placeholder="Email" />
                <Button onClick={handleSubmit}>Reestablecer contraseña</Button>
                <Parrafo>Se enviará un correo electrónico para cambiar la contraseña.</Parrafo>
            </>
        }
        {
            error && <Mensaje>
                <Parrafo>{error}</Parrafo>
            </Mensaje>
        }
    </Fila>
}