import React, { useState, useContext, useMemo } from "react";
import firebase from "firebase";
import styled from "styled-components";
import { Titulo, Input, Button, Mensaje, Grid, Parrafo, Columna, Fila } from "../../components";
import { ICliente, nuevoCliente, clienteFromSnapshot } from "../../modelos";
import { AuthContext } from "../../contextos";
import { object, string, ValidationError } from "yup";

const NuevoClienteComp = (props) => {
    const { cliente: cli } = useContext(AuthContext);
    const [cliente, setCliente] = useState<ICliente>(nuevoCliente());
    const [resultado, setResultado] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [erroresForm, setErroresForm] = useState<ValidationError | undefined>(undefined);
    const [isValid, setIsValid] = useState(false);

    const formSchema = useMemo(() => object({
        nombre: string().required(`El nombre es obligatorio`).min(2, "El nombre debe contener al menos 2 dígitos").max(150, `El nombre debe contener 150 dígitos como máximo`),
        email: string().required("El Email es obligatorio").email("El email no es válido"),
        codigo: string().required("El código es obligatorio").max(100, `Debe contener 100 dígitos como máximo`),
        cif: string(),
    }), []);


    const handleSubmit = (ev) => {
        ev.preventDefault();
        setError(undefined);
        setResultado(undefined);
        (async () => {
            if (isValid) {
                try {
                    const existe = await firebase.firestore().collection("clientes").where("email", "==", cliente?.email).get();
                    if (!existe.empty) {
                        console.log("El cliente ya existe");
                        setError("El correo electrónico ya se ha registrado para otro cliente");
                        setIsValid(false);
                        return;
                    }


                    const ref = firebase.firestore().collection("clientes").doc();
                    const c: ICliente = { ...cliente, id: ref.id, createdAt: new Date(), createdBy: cli?.id, updatedBy: cli?.id };
                    console.log("Guardamos el cliente ", c);
                    await ref.set(c);
                    setCliente(nuevoCliente());
                    setResultado("Cliente añadido correctamente");
                } catch (error) {
                    setError(error.message);
                }
            }
        })();
    }

    const handleChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!!resultado || !!error) {
            setResultado(undefined);
            setError(undefined);
        }
        const cli = { ...cliente, [ev.target.name]: ev.target.type === "checkbox" ? ev.target.checked : ev.target.value }
        setCliente(cli);
        try {
            await formSchema.validate(cli);
            setErroresForm(undefined);
            setIsValid(true);
        } catch (error) {
            console.error(error);
            setIsValid(false);
            setErroresForm(error);
        }
    }

    return <Fila gap="1rem">
        <Titulo>Nuevo cliente</Titulo>
        <form onSubmit={handleSubmit}>
            <Grid gap="1rem" style={{ gridTemplateColumns: "repeat(2, minmax(20rem, 1fr))" }}>
                <Input type="text" name="codigo" value={cliente.codigo} onChange={handleChange} placeholder="Código de cliente" maxLength={10} style={{ borderColor: erroresForm?.path === "codigo" && erroresForm?.errors[0] ? "red" : "" }} />
                {/* {erroresForm?.path === "codigo" && <p>{erroresForm.errors[0]}</p>} */}
                <Input type="email" name="email" value={cliente.email} onChange={handleChange} placeholder="Email" maxLength={150} style={{ borderColor: erroresForm?.path === "email" && erroresForm?.errors[0] ? "red" : "" }} />
                <Input type="text" name="nombre" value={cliente.nombre} onChange={handleChange} placeholder="Nombre" maxLength={150} style={{ borderColor: erroresForm?.path === "nombre" && erroresForm?.errors[0] ? "red" : "" }} />
                <Input type="text" name="cif" value={cliente.cif} onChange={handleChange} placeholder="CIF" maxLength={10} style={{ borderColor: erroresForm?.path === "cif" && erroresForm?.errors[0] ? "red" : "" }} />
                <Columna>
                    <label htmlFor="carpetaCompartida">
                        <Columna sizes="auto 1fr" gap="1rem">
                            <Input name="carpetaCompartida" type="checkbox" onChange={handleChange} checked={cliente.carpetaCompartida} />
                            <Parrafo>Acceso a carpeta compartida</Parrafo>
                        </Columna>
                    </label>
                    {/* <label htmlFor="carpetaCompartida">
                        <Columna sizes="auto 1fr" gap="1rem">
                            <Input name="isAdmin" type="checkbox" onChange={handleChange} checked={cliente.isAdmin} />
                            <Parrafo>Es administrador</Parrafo>
                        </Columna>
                    </label> */}
                </Columna>
                <Button type="submit" disabled={!isValid} >Crear el cliente</Button>
                {!!resultado && <Mensaje>{resultado}</Mensaje>}
                {!!error && <Mensaje>{error}</Mensaje>}
                {!!erroresForm && <Mensaje>{erroresForm.message}</Mensaje>}
            </Grid>

        </form>
    </Fila>
}

export const NuevoCliente = styled(NuevoClienteComp)``;