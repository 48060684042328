export class Utils {
    // static clonar = <T extends object>(objeto: T): T => {
    //     return cloneDeep(objeto) as T;
    // }

    // static comparar = <T extends object>(original: T, otro: T): boolean => {
    //     return isEqual(original, otro);
    // }

    static forEachAsync = async <T extends object | string>(array: T[], callback: (objeto: T, index: number, coleccion: T[]) => void) => {
        for (let i = 0; i < array.length; i++) {
            await callback(array[i], i, array);
        }
    }
}