import React, { useContext } from "react";
import firebase from "firebase";
import logo from "../../assets/images/logo.png";
import styled from "styled-components";
import { AuthContext } from "../../contextos";
import { Parrafo, Columna } from "../../components";

const LogoutButton = styled.button`
    cursor:pointer;
    border:1px solid #ccc;
    background: transparent;
    font-size: 1rem;
    padding:0.3rem 0.5rem;
    border-radius: 3px;
`;


const HeaderComp = (props) => {
    const { cliente } = useContext(AuthContext);

    const logout = () => {
        (async () => firebase.auth().signOut())();
    }

    return (
        <header className={props.className}>
            <div className=""></div>
            <a className="logotipo" href="https://consultoresgm.es" title="Grupo GM"><img src={logo} alt="Grupo Gm" /></a>
            {/* <nav>
                <a href="https://consultoresgm.es" title="Grupo GM">Grupo GM</a>
                {!!cliente?.isAdmin && <NavLink to="/admin/clientes" activeClassName="active">Clientes</NavLink>}
            </nav> */}
            <Columna gap="1rem" sizes="1fr auto" align="center">
                <Parrafo style={{ textAlign: "right", height: "100%", padding: "5px 0" }}>{cliente?.nombre}</Parrafo>
                <LogoutButton onClick={logout}>Cerrar sesión</LogoutButton></Columna>
        </header>
    );
};

export const Header = styled(HeaderComp)`
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #ccc;
    display: grid;
    gap: 1rem;
    grid-template-columns: 2fr 200px 2fr;
    img{
        max-width: 180px;
    }
    nav{
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
    .logotipo{
        text-align: center
    }
`;