import React from "react";
import logo from "../assets/images/logo.png";
import { Grid, Fila } from "../components";
import { RememberForm } from "./components";
export const Password = () => {
    return <Grid height="100%" align="center" justify="center">
        <Grid align="center" gap="5rem">
            <Fila>
                <img src={logo} alt="Grupo Gm" />
            </Fila>
            <RememberForm />
        </Grid>
    </Grid>
}