type IEnvironment = {
    firebase: any;
    EnviarLinkClienteUrl: string
}

const prod: IEnvironment = {
    firebase: {
        apiKey: "AIzaSyCVFRkw2gvO_4iErE-E97HLfaFwjC9QDLE",
        authDomain: "grupogm-clientes.firebaseapp.com",
        databaseURL: "https://grupogm-clientes.firebaseio.com",
        projectId: "grupogm-clientes",
        storageBucket: "grupogm-clientes.appspot.com",
        messagingSenderId: "29562403914",
        appId: "1:29562403914:web:cf850cdd7c85e2b1d6bab3"
    },
    EnviarLinkClienteUrl: "https://europe-west1-grupogm-clientes.cloudfunctions.net/enviarEnlaceCliente"
};

const dev: IEnvironment = {
    firebase: {
        apiKey: "AIzaSyBKOO4z0hSrrhPgHTaeQaO-A12S3sXElxA",
        authDomain: "gm-desarrollo.firebaseapp.com",
        databaseURL: "https://gm-desarrollo.firebaseio.com",
        projectId: "gm-desarrollo",
        storageBucket: "gm-desarrollo.appspot.com",
        messagingSenderId: "825057032271",
        appId: "1:825057032271:web:733805fabec987bf01d23a"
    },
    EnviarLinkClienteUrl: "https://europe-west1-gm-desarrollo.cloudfunctions.net/enviarEnlaceCliente"
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;




// export const environment = process.env.NODE_ENV === 'production' ?
//     //Production
//     {
//         firebase: {
//             apiKey: "AIzaSyCVFRkw2gvO_4iErE-E97HLfaFwjC9QDLE",
//             authDomain: "grupogm-clientes.firebaseapp.com",
//             databaseURL: "https://grupogm-clientes.firebaseio.com",
//             projectId: "grupogm-clientes",
//             storageBucket: "grupogm-clientes.appspot.com",
//             messagingSenderId: "29562403914",
//             appId: "1:29562403914:web:cf850cdd7c85e2b1d6bab3"
//         },
//         //functionsURL: "https://europe-west1-progesnet-af.cloudfunctions.net/"
//     } :
//     //Development
//     {
//         firebase: {
//             apiKey: "AIzaSyCVFRkw2gvO_4iErE-E97HLfaFwjC9QDLE",
//             authDomain: "grupogm-clientes.firebaseapp.com",
//             databaseURL: "https://grupogm-clientes.firebaseio.com",
//             projectId: "grupogm-clientes",
//             storageBucket: "grupogm-clientes.appspot.com",
//             messagingSenderId: "29562403914",
//             appId: "1:29562403914:web:cf850cdd7c85e2b1d6bab3"
//         },
//         //functionsURL: "http://localhost:5000/progesnet-dev/us-central1/"
//     }
