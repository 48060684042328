import React from "react";
import logo from "../assets/images/logo.png";
import { Grid, Fila } from "../components";
import { LoginForm } from "./components";
export const Login = () => {


    return <div style={{ height: "100vh", width: "100%" }}>
        <Grid height="100%" align="center" justify="center">
            <Grid gap="5rem">
                <Fila>
                    <img src={logo} alt="Grupo Gm" />
                </Fila>
                <LoginForm />
            </Grid>
        </Grid>
    </div>
}