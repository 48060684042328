import styled from "styled-components";


export const Titulo = styled.h1`
    font-size: 2rem;
    font-weight: normal;
    line-height: 2.2rem;
`;

export const Parrafo = styled.p`
    font-size: 1rem;
    line-height: 1.1rem;
`;

export const Mensaje = styled.div`
    border: 1px solid red;
    background: orange;
    padding: 1rem;
    p{
        font-size: 1rem;
    line-height: 1.1rem;
    }
`;